<template>
  <v-container>
    <div id="nav">
      <keep-alive>
        <Nav></Nav>
      </keep-alive>
    </div>
    <v-row>
      <v-card flat class="mx-auto" max-width="1000">
        <v-row align="center">
          <v-col cols="5">
            <v-img contain :max-width="height" max-height="500" :src="data[plate_name].img"></v-img>
          </v-col>
          <v-col>
            <v-card-title><h2>{{ data[plate_name].name }}</h2></v-card-title>
            <v-card-text>
              <div class="font-weight-medium">{{ data[plate_name].text }}</div>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
    <v-col>
      <v-card flat class="mx-auto" max-width="500" v-show="data[plate_name].list.length">
        <v-card-title class="headline">游戏目录</v-card-title>
        <v-list>
          <v-list-item-group v-model="model">
            <v-list-item
                v-for="i in data[plate_name].list"
                :key="i.name"
                :to="i.url"
            >
              <v-list-item-icon>
                <v-icon>mdi-send</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="i.name" class="title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-col>
    <v-col>
      <v-card flat class="mx-auto" max-width="500" v-show="data[plate_name].article">
        <v-card-title class="headline">相关教程</v-card-title>
        <v-list>
          <v-list-item-group v-model="model">
            <v-list-item
                v-for="i in data[plate_name].article"
                :key="i.name"
                :to="i.url"
            >
              <v-list-item-icon>
                <v-icon>mdi-send</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="i.name" class="title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-col>
  </v-container>
</template>

<script>
import Nav from "@/components/Nav";
import router from "@/router";

export default {
  props: ['plate_name'],
  components: {
    Nav
  },
  data: () => ({
    data: '',
    items: '',
    model: 1,
  }),
  computed: {
    height: function () {
      if (this.$vuetify.breakpoint.name === 'xs') {
        return 250
      } else if (this.$vuetify.breakpoint.name === 'sm') {
        return 300
      } else if (this.$vuetify.breakpoint.name === 'md') {
        return 350
      } else if (this.$vuetify.breakpoint.name === 'lg') {
        return 400
      } else if (this.$vuetify.breakpoint.name === 'xl') {
        return 450
      } else return 300
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.axios
          .get('https://data.yang2000ling.com/data/plate.json')
          .then(response => (this.data = response.data))
          .catch(error => {
                console.log(error)
                this.errored = true
                router.push('/')
              }
          )
    }
  },
}
</script>

<style scoped>

</style>